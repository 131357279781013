<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat><span :style="{'color': colores.primario}" style="font-size:20px"><b>HISTORIAL DE ASIGNACIONES</b></span></v-toolbar>
      <v-card-text class="pt-0">
          <div v-for="(item, index) in registro.escalamientos" :key="index">
            <v-row>
              <v-col cols="12" md="1">
                <v-avatar size="30" :color="colores.primario" class="white--text">{{ index + 1 }}</v-avatar>
              </v-col>
              <v-col cols="12" md="5">
                <v-row no-gutters>
                  <v-col cols="12" v-if="item.usuario">
                      <b>{{ item.usuario.nombre_usuario + ' ' + item.usuario.apellido_usuario }}</b>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                      {{ rec_function_fecha_colombia(item.created_at) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row no-gutters>
                  <v-col cols="12">
                      <b>{{ item.escaladoa.nombre_usuario + ' ' + item.escaladoa.apellido_usuario }}</b>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                      {{ item.fecha_cerrado }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider>S</v-divider>
          </div>
        </v-card-text>
    </v-card>
  </v-sheet>
</div>
</template>

<script>
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'crm_lectura_solicitanteComponent',
  components: {

  },
  mixins: [recFunciones],
  props: ['colores', 'registro'],
  data: () => ({
  }),
  created () {

  },
  methods: {

  }

}
</script>
